// @ts-nocheck
class UnsupportedFileClass {
	constructor(data) {
		this.name = data.name;
		this.link = data.link;
		this.mimeType = data.mimeType;
	}
}

export default UnsupportedFileClass;
